import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Flex, Image, Button, HStack, Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import sendemail from '../../assets/images/group-75.webp';
import logo from '../../assets/images/logo.svg';
import footerlogo from '../../assets/images/logofooter.svg';
import arrow from '../../assets/images/Arrow 2.svg';
import arrow2 from '../../assets/images/Line 5.svg';
import arrowUp from '../../assets/images/Group 388 (1).png';
import right from '../../assets/images/Group 391.svg';
import right2 from '../../assets/images/Group 390.svg';
import rectangle from '../../assets/images/Rectangle 38.svg';
import shape from '../../assets/images/shape.svg';
import shape2 from '../../assets/images/shape2.svg';
import Logo from '../Logo/Logo';
import './SendEmail.css';

function SendEmail() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const paths = {
        en: {
            rgpd: 'GDPR',
            form: 'form',
            sendEmail: 'sent',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
        fr: {
            rgpd: 'RGPD',
            form: 'formulaire',
            sendEmail: 'envoye',
            nosServices: 'nos-services',
            fintech: 'nos-services/developpement-fintech',
            ia: 'nos-services/developpement-ia',
            foodtech: 'nos-services/developpement-foodtech',
            mobile: 'nos-services/developpement-application-mobile',
            web: 'nos-services/developpement-site-web',
            blockchain: 'nos-services/developpement-blockchain',
            startup: 'nos-services/developpement-start-up',
        },
        ar: {
            rgpd: 'GDPR',
            form: 'form',
            sendEmail: 'sent',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
    };
    return (
        <div className="send-email-container">
            <img src={sendemail} alt='send' className="send-email-image" />
            <HStack display="flex" justifyContent="space-between">
                <div className="header-content">
                    <NavLink to='/'>
                        <button className='fleche' id='fleche'><img src={arrow} alt='arrow' /></button>
                    </NavLink>
                    <div alt="logo" className="header-logo" id='second_logo' >
                        <Logo />
                    </div>
                </div>
                <Button className='contactButton'>
                    <NavLink id={`${isRTL ? 'titles-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].form}`}> {t('contact')}</NavLink>
                </Button>
            </HStack>
            <div className='content-container' style={{ paddingBottom: '190px' }}>
                <div className='container2' style={{ display: 'flex' }}>
                    <NavLink to={`/${currentLang}`}>
                        <h4 className={isRTL ? 'letter-rtl2' : 'link1'}>{t('Form_page.section1.title1')}/ </h4>
                    </NavLink>
                    <NavLink to={`/${currentLang}/${paths[currentLang].form}`}>
                        <h4 className={isRTL ? 'letter-rtl1' : 'link2'}>{t('Form_page.section1.title2')}</h4>
                    </NavLink>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <div className='BOX' style={{ position: 'relative' }}>
                        <h3 id='h3-style send-message' className={isRTL ? 'send-message-rtl' : 'send-message'}>{t('Form_page.section6.title')}</h3>
                        <h1 className='h1-style2 h1-title' id={isRTL ? 'h1-style2-rtl' : 'h1-style2'} style={{ marginLeft: '0' }}> {t('Form_page.section6.subtitle')}</h1>
                        <h4 className={isRTL ? 'send-reply-rtl' : 'send-reply'}>{t('Form_page.section6.description1')}</h4>
                        <h4 className={isRTL ? 'choossing-us-rtl' : 'choossing-us'}>{t('Form_page.section6.description2')}</h4>
                        <NavLink to={`/${currentLang}`}>
                            <button className={isRTL ? 'button-rtl' : ''} type='submit' style={{ marginLeft: '0' }}>{t('Form_page.section6.button')}</button>
                        </NavLink>
                        <img src={arrow2} alt="arrow" className="arrow-image arrow2" />
                        <h3 className={isRTL ? 'first-step-rtl' : 'first-step'}>
                            <img id='shape1' src={shape} alt='shape' /> {t('Form_page.section6.description3')}
                        </h3>
                        <h1 className={isRTL ? 'project-successful-rtl' : 'project-successful'}>
                            {t('Form_page.section6.description4')}<img id='shape2' src={shape2} alt='shape' />
                        </h1>
                        <h4 className='omniverse-lab'>-OMNIVERSE LAB BOT</h4>
                    </div>
                    <img src={right} alt='right' className="right-image" />
                    <img src={right2} alt='right' id="right-image" />
                </div>
            </div>
            <img src={rectangle} alt='rectangle' className='rectangle' />
            <button className="arrow-up-container scroolup" onClick={scrollToTop}>
                <img src={arrowUp} alt="arrow up" className="arrow-up" />
            </button>
            <Flex justifyContent="center" alignItems="center" direction="column">
                <ul className="footer-menu1" id='footermenu2'>
                    <li className='logo'><Image id='logo' src={footerlogo} alt="Logo" style={{ marginRight: '15px', marginLeft: '25px' }} /></li>
                    <li className={`${isRTL ? 'li-rtl' : 'li'}`}> <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')} </NavLink> - <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc16')}</NavLink> </li>
                    <li className={`${isRTL ? 'li-rtl' : 'li'}`}> <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>© 2024 Omniverselab </NavLink>-<NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}> {t('desc17')} </NavLink></li>
                </ul>
            </Flex>
            <Flex justifyContent="center" alignItems="center">
                <Flex
                    className="footer-menu-res"
                    id='footermenu'
                    align="center"
                    justify="center"
                    width="100%"
                    fontFamily="Poppins"
                    fontSize="16px"
                    fontWeight='200'
                    lineHeight="24px"
                    letterSpacing="0.1em"
                    textAlign="left"
                >
                    <Flex flex="1" justify="flex-end">
                        <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')} - </NavLink>
                        <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc16')}</NavLink>
                    </Flex>
                    <Image src={footerlogo} alt="Logo" boxSize="60px" mx="25px" />
                    <Flex flex="1" justify="flex-start">
                        <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>&copy; 2024 Omniverselab - </NavLink>
                        <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc17')} </NavLink>
                    </Flex>
                </Flex>
            </Flex>
            <Text id={`${isRTL ? 'footer-rtl' : 'nos-service'}`} display='flex' justifyContent='center' color='rgba(255, 255, 255, 0.7)' fontSize='16px' fontFamily='Poppins' lineHeight='24px' mt={10} fontWeight={200}>{t('Contact.title')}</Text>
            <Box className='footercontent-container'>
                <Box className='footercontent' id={`${isRTL ? 'footer-rtl' : ''}`}>
                    <ul  >
                        <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].fintech}`}>{t('Contact.items.item1')}</NavLink></li>
                        <li> <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].ia}`}>{t('Contact.items.item2')}</NavLink></li>
                        <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].foodtech}`}>{t('Contact.items.item3')}</NavLink></li>
                    </ul>
                    <ul>
                        <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].mobile}`}>{t('Contact.items.item4')}</NavLink></li>
                        <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].web}`}>{t('Contact.items.item5')}</NavLink></li>
                        <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].blockchain}`}>{t('Contact.items.item6')}</NavLink></li>
                    </ul>
                    <ul>
                        <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].startup}`}>{t('Contact.items.item7')}</NavLink></li>
                    </ul>
                </Box>
            </Box>
        </div >
    );
}

export default SendEmail;
