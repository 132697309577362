import React from 'react';
import { Box, Flex, HStack, } from '@chakra-ui/react';
import stars from '../../assets/images/smallstarts.svg'
import background from '../../assets/images/back.webp';
import './Header.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from '../Navbar/Navbar';
import Brends from '../Brends/Brends';

function Header() {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';

    const paths = {
        en: {
            form: 'form',
            nosServices: 'our-services',
        },
        fr: {
            form: 'formulaire',
            nosServices: 'nos-services',
        },
        ar: {
            form: 'form',
            nosServices: 'our-services',
        },
    };

    return (
        <header>
            <Helmet>
                <title>Omniverse Lab</title>
            </Helmet>
            <Box
                className="responsive-background"
                bgImage={background}
                bgPosition="center"
                bgSize="cover"
                height="auto"
            >
                <Navbar />
                <Flex direction="column" align="flex-start" justify="center" h="100%">
                    <div className={isRTL ? 'container-rtl' : 'container-ltr'}>
                        <div className={isRTL ? 'containerRTL' : 'container'}>
                            <HStack className={isRTL ? 'starRTL' : 'starLTR'} style={{ width: '100%' }} >
                                <h1 className="h1-style">{t('message')}</h1>
                                <img style={{ marginLeft: '-20px', marginBottom: '45px' }} src={stars} alt='stars' />
                            </HStack>
                            <h1 style={{ width: '100%' }} className='h1-style2'>{t('title1')}</h1>
                            <p className='p1-style'>
                                {t('word1')}<span> {t('word2')} </span>{t('word3')}<span> {t('word4')} </span>{t('word5')}
                            </p>
                            <p className='p2-style' style={{ marginBottom: '0', paddingBottom: '20px' }}>
                                {t('paragraphe1')} <strong>{t('paragraphe2')} </strong>{t('paragraphe3')}
                            </p>
                        </div>
                        <NavLink to={`/${currentLang}/${paths[currentLang].form}`} aria-label={t('button1')}>
                            <button id='headerbtn' style={{ marginBottom: '120px', marginTop: '30px', marginRight: '10px' }}>
                                {t('button1')}
                            </button>
                        </NavLink>
                    </div>
                </Flex>
            </Box>
            <Brends />
        </header>
    );
}

export default Header;
