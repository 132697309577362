//
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../i18n/langues/EN.json';
import translationFR from '../i18n/langues/FR.json';
import translationAR from '../i18n/langues/AR.json';
const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
    ar: {
        translation: translationAR,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['path', 'navigator', 'querystring', 'cookie', 'localStorage'],
            caches: ['localStorage', 'cookie']
        },
        resources,
        interpolation: {
            escapeValue: false,
        },
        fallbackLng: 'en',
        whitelist: ['en', 'fr', 'ar'],
        load: 'languageOnly',
        react: {
            useSuspense: false,
        },
    });

export default i18n;
