import React, { useState, useRef } from 'react';
import { Box, HStack, Button, Text, Flex, GridItem } from '@chakra-ui/react';
import articleImg from '../../../assets/images/bg_article.webp';
import { useTranslation } from 'react-i18next';
import './Article.css';
import line from '../../../assets/images/Line 19.svg';
import arrow from '../../../assets/images/Arrow 3 (1).svg';
import arrow3 from '../../../assets/images/Arrow 3.svg';
import activeDot from '../../../assets/images/active.svg';
import inactiveDot from '../../../assets/images/not active.svg';
import { NavLink } from 'react-router-dom';
function Content() {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';
    const paths = {
        en: {
            rgpd: 'GDPR',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
        fr: {
            rgpd: 'RGPD',
            nosServices: 'nos-services',
            fintech: 'nos-services/developpement-fintech',
            ia: 'nos-services/developpement-ia',
            foodtech: 'nos-services/developpement-foodtech',
            mobile: 'nos-services/developpement-application-mobile',
            web: 'nos-services/developpement-site-web',
            blockchain: 'nos-services/developpement-blockchain',
            startup: 'nos-services/developpement-start-up',
        },
        ar: {
            rgpd: 'GDPR',
            form: 'form',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
    };
    const boxInfo = [
        { title: t('Blog.menu.blockchain'), date: "01.11.2021", description: t('Contact.items.item6'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].blockchain}` },
        { title: t('Blog.menu.development'), date: "01.11.2021", description: t('Contact.items.item1'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].fintech}` },
        { title: t('Blog.menu.ia'), date: "01.11.2021", description: t('Contact.items.item2'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].ia}` },
        { title: t('Blog.menu.development'), date: "01.11.2021", description: t('Contact.items.item3'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].foodtech}` },
        { title: t('Blog.menu.development'), date: "01.11.2021", description: t('Contact.items.item5'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].web}` },
        { title: t('Blog.menu.development'), date: "01.11.2021", description: t('Contact.items.item7'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].startup}` },
    ];

    const [currentPage, setCurrentPage] = useState(0);
    const boxesPerPage = 4;

    const handleRightClick = () => {
        setCurrentPage((prevPage) => (prevPage + 1) % Math.ceil(boxInfo.length / boxesPerPage));
    };

    const handleLeftClick = () => {
        setCurrentPage((prevPage) => (prevPage - 1 + Math.ceil(boxInfo.length / boxesPerPage)) % Math.ceil(boxInfo.length / boxesPerPage));
    };

    const handleTouchStart = (e) => {
        const touch = e.touches[0];
        setTouchStartX(touch.clientX);
    };

    const handleTouchMove = (e) => {
        if (!touchStartX) return;
        const touch = e.touches[0];
        setTouchEndX(touch.clientX);
    };

    const handleTouchEnd = () => {
        if (!touchStartX || !touchEndX) return;
        const distance = touchStartX - touchEndX;
        const minSwipeDistance = 50;

        if (distance > minSwipeDistance) {
            handleRightClick();
        } else if (distance < -minSwipeDistance) {
            handleLeftClick();
        }

        setTouchStartX(null);
        setTouchEndX(null);
    };

    const currentBoxes = boxInfo.slice(currentPage * boxesPerPage, (currentPage + 1) * boxesPerPage);

    const [touchStartX, setTouchStartX] = useState(null);
    const [touchEndX, setTouchEndX] = useState(null);

    return (
        <Box
            bgImage={`url(${articleImg})`}
            bgPosition="center"
            bgSize="cover"
            height="auto"
            width="100%"
            position="relative"
            overflow={'hidden'}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div className={`${isRTL ? '' : 'article_container'}`}>
                <Text className={`${isRTL ? 'content-rtl' : ''}`}
                    id='Fintechtitlle' style={{
                        fontFamily: 'Poppins',
                        fontSize: '30.54px',
                        fontStyle: 'italic',
                        fontWeight: 900,
                        marginTop: '30px',
                        lineHeight: '43.81px',
                        letterSpacing: '0.1em',
                        color: 'rgba(255, 255, 255, 1)'
                    }}>
                    {t('Blockchain_section.intro')}
                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.paragraphe1')}
                </p>
                <Text className={`${isRTL ? 'content-rtl' : ''}`} id='Fintechtitlle' style={{
                    fontFamily: 'Poppins',
                    fontSize: '30.54px',
                    fontStyle: 'italic',
                    fontWeight: 900,
                    marginTop: '30px',
                    lineHeight: '43.81px',
                    letterSpacing: '0.1em',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Mobile_section.title2')}
                </Text>
                <Text className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{
                    fontFamily: 'Raleway',
                    fontSize: '16.19px',
                    fontWeight: 800,
                    letterSpacing: '0.13em',
                    textAlign: 'left',
                    marginTop: '30px',
                    color: 'rgba(255, 255, 255, 1)'
                }}>

                    {t('Mobile_section.subtitle1')}

                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p1')}
                </p>
                <Text className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{
                    fontFamily: 'Raleway',
                    fontSize: '16.19px',
                    fontWeight: 800,
                    letterSpacing: '0.13em',
                    marginTop: '30px',
                    color: 'rgba(255, 255, 255, 1)'
                }}>

                    {t('Mobile_section.subtitle2')}

                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p2')}
                </p>
                <Text className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{
                    fontFamily: 'Raleway',
                    fontSize: '16.19px',
                    fontWeight: 800,
                    letterSpacing: '0.13em',
                    marginTop: '30px',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Mobile_section.subtitle3')}

                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p3')}
                </p>
                <Text className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{
                    fontFamily: 'Raleway',
                    fontSize: '16.19px',
                    fontWeight: 800,
                    letterSpacing: '0.13em',
                    marginTop: '30px',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Mobile_section.subtitle4')}

                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p4')}
                </p>
                <Text className={`${isRTL ? 'content-rtl' : ''}`} id='Fintechtitlle' style={{
                    fontFamily: 'Poppins',
                    fontSize: '30.54px',
                    fontStyle: 'italic',
                    fontWeight: 900,
                    marginTop: '30px',
                    lineHeight: '43.81px',
                    letterSpacing: '0.1em',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Mobile_section.our_services')}
                </Text>
                <Text className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{
                    fontFamily: 'Raleway',
                    fontSize: '16.19px',
                    fontWeight: 800,
                    letterSpacing: '0.13em',
                    marginTop: '30px',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Mobile_section.subtitle5')}
                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p5')}
                </p>
                <Text className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{
                    fontFamily: 'Raleway',
                    fontSize: '16.19px',
                    fontWeight: 800,
                    letterSpacing: '0.13em',
                    marginTop: '30px',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Mobile_section.subtitle6')}
                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p6')}
                </p>
                <Text className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{
                    fontFamily: 'Raleway',
                    fontSize: '16.19px',
                    fontWeight: 800,
                    letterSpacing: '0.13em',
                    marginTop: '30px',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Mobile_section.subtitle7')}
                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p7')}
                </p>
                <Text className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{
                    fontFamily: 'Raleway',
                    fontSize: '16.19px',
                    fontWeight: 800,
                    letterSpacing: '0.13em',
                    marginTop: '30px',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Mobile_section.subtitle8')}
                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p8')}
                </p>
                <Text className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{
                    fontFamily: 'Raleway',
                    fontSize: '16.19px',
                    fontWeight: 800,
                    letterSpacing: '0.13em',
                    marginTop: '30px',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Mobile_section.subtitle9')}
                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p9')}
                </p>

                <Text className={`${isRTL ? 'content-rtl' : ''}`} id='Fintechtitlle' style={{
                    fontFamily: 'Poppins',
                    fontSize: '30.54px',
                    fontStyle: 'italic',
                    fontWeight: 900,
                    marginTop: '30px',
                    lineHeight: '43.81px',
                    letterSpacing: '0.1em',
                    color: 'rgba(255, 255, 255, 1)'
                }}>
                    {t('Blockchain_section.conclusion')}

                </Text>
                <p className={`${isRTL ? 'content-rtl text-rtl' : ''}`} style={{ fontFamily: "Raleway", fontSize: '15px', fontWeight: "400", lineHeight: '26.66px', letterSpacing: '0.095em', textAlign: 'left', color: "rgba(255, 255, 255, 1)" }}>
                    {t('Mobile_section.p10')}
                    <br></br><br></br>
                    {t('Mobile_section.p11')}
                </p>
                <Box width="100%" height="1px" backgroundColor="rgba(255, 255, 255, 0.41)" mb={20} mt={80} />
                <Box
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                >
                    <Text className={`${isRTL ? 'titles-rtl' : ''}`} id='Fintechtitlle'
                        style={{
                            fontFamily: 'Poppins',
                            fontSize: '30.54px',
                            fontWeight: 600,
                            marginTop: '30px',
                            lineHeight: '43.81px',
                            letterSpacing: '0.1em',
                            marginBottom: '5px',
                            color: 'rgba(255, 255, 255, 1)'

                        }}
                    >
                        {t('Service.service')}
                    </Text>
                    <Box
                        id={`${isRTL ? 'Line-rtl' : 'Line'}`}
                        height="1px"
                        width={320}
                        backgroundColor="rgba(129, 118, 255, 1)"
                        mb={20}
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    />
                </Box>
            </div>
            <Flex justify="center" align="center">
                <button className='rightbutton' onClick={handleLeftClick}><img src={arrow} alt='left arrow' /></button>
                <div className="grid-container">
                    {currentBoxes.map((box, index) => (
                        <GridItem key={index} backgroundColor='rgba(255, 255, 255, 0.08)' padding={25} borderRadius={10} ml={20} mr={20} >
                            <Text
                                id={isRTL ? 'titles-rtl' : ''}
                                className={
                                    box.title === t('Blog.menu.development') ? "category-fintech" :
                                        box.title === t('Blog.menu.ia') ? "category-design" :
                                            box.title === t('Blog.menu.blockchain') ? "category-category" :
                                                "category"
                                }
                            >
                                {box.title}
                            </Text>
                            <HStack justifyContent="space-between" alignItems="baseline" id={`${isRTL ? 'card-titles-rtl' : ''}`}>
                                <Text id={`${isRTL ? 'titles-rtl' : ''}`} className="article_title">{box.description}</Text>
                                <NavLink to={`/${currentLang}/` + box.link}>
                                    <Button id={`${isRTL ? 'titles-rtl' : ''}`} className="article_button">{t('Service.readmore')}</Button>
                                </NavLink>
                            </HStack>
                        </GridItem>
                    ))}
                </div>
                <button className='rightbutton' onClick={handleRightClick}><img src={arrow3} alt='right arrow' /></button>
            </Flex>
            <Flex mt={4} justify="center">
                {Array.from({ length: Math.ceil(boxInfo.length / boxesPerPage) }).map((_, index) => (
                    <img
                        key={index}
                        src={index === currentPage ? activeDot : inactiveDot}
                        alt={index === currentPage ? 'active dot' : 'inactive dot'}
                        style={{ marginTop: '30px', marginBottom: '30px', marginLeft: '-2px' }}
                    />
                ))}
            </Flex>
        </Box >
    );
}

export default Content;
