// TitleComponent.jsx
import React from 'react';
import { Helmet } from 'react-helmet';

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const TitleComponent = ({ title }) => {
    const formattedTitle = capitalizeFirstLetter(title);

    return (
        <Helmet>
            <title>{formattedTitle} | Omniverse Lab</title>
        </Helmet>
    );
};

export default TitleComponent;
