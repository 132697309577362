import React, { useEffect } from 'react';
import './FormPage.css';
import planaite from '../../assets/images/Group-418.png';
import logo from '../../assets/images/logo.svg';
import arrow from '../../assets/images/Arrow 2.svg';
import { NavLink } from 'react-router-dom';
import Contact from './Contact';
import { Box, Button, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Logo from '../Logo/Logo';
import Titles from '../Titles'
function FormPage() {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const paths = {
        en: {
            rgpd: 'GDPR',
            form: 'form',
        },
        fr: {
            rgpd: 'RGPD',
            form: 'formulaire',
        },
        ar: {
            rgpd: 'GDPR',
            form: 'form',
        },
    };
    return (
        <>
            <Titles title={`${t('contact')}`} />

            <Box
                bgImage={`url(${planaite})`}
                bgPosition="center"
                bgSize="cover"
                height="auto"
                width="100%"
                position="relative"
                overflow={'hidden'}
            >
                <HStack display="flex" justifyContent="space-between">
                    <div className="header-content">
                        <NavLink to='/'> <button className='fleche' id='fleche'>
                            <img src={arrow} alt='arrow' /></button></NavLink>
                        <div alt="logo" className="header-logo" id='second_logo' >
                            <Logo />
                        </div>
                    </div>
                    <Button className='contactButton'>
                        <NavLink id={`${isRTL ? 'titles-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].form}`}> {t('contact')}</NavLink>
                    </Button>
                </HStack>
                <div className='content-container' style={{ paddingBottom: '190px' }}>
                    <div className='container2' style={{ display: 'flex' }}>
                        <NavLink to='/'><h4 className={isRTL ? 'letter-rtl2' : 'link1'}>{t('Form_page.section1.title1')}/ </h4></NavLink>
                        <NavLink to={`/${currentLang}/${paths[currentLang].form}`} > <h4 className={isRTL ? 'letter-rtl1' : 'link2'}> {t('Form_page.section1.title2')}</h4></NavLink>
                    </div>

                    <div className={isRTL ? 'container2-rtl' : 'container2'}>
                        <h1 className='h1-style' id={isRTL ? 'rtl' : ''}>{t('Form_page.section1.title2')}</h1>
                        <h1 className='h1-style2' id='h1-style2 hs'>{t('Form_page.section2.title1')} </h1>
                        <p className='p1-style' id={isRTL ? 'rtl' : ''}><span id='p1-style'>{t('Form_page.section2.description1')}</span> {t('Form_page.section2.description2')} <span id='p1-style'>{t('Form_page.section2.description3')}</span></p>
                        <p className='p2-style' id={isRTL ? 'rtl' : 'p2-style'} >{t('Form_page.section2.description4')}</p>
                    </div>
                </div>
                <div style={{ textAlign: 'center', position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)' }}>
                    <h1 id={isRTL ? 'content-title-rtl' : 'contact-title'}>{t('Form_page.section2.title2')}</h1>
                    <h1 id={isRTL ? 'content-title-rtl2' : 'contact-title2'}>{t('Form_page.section2.title3')}</h1>
                </div>
            </Box>
            <Contact />
        </>
    );
}

export default FormPage;
