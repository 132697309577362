import React from 'react'
import { Box, Text, Image, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import footerlogo from '../../../assets/images/logofooter.svg';
import arrowUp from '../../../assets/images/Group 388 (1).png';
import rectangle from '../../../assets/images/Rectangle 38.svg';
function Footer() {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const paths = {
        en: {
            rgpd: 'GDPR',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
        fr: {
            rgpd: 'RGPD',
            nosServices: 'nos-services',
            fintech: 'nos-services/developpement-fintech',
            ia: 'nos-services/developpement-ia',
            foodtech: 'nos-services/developpement-foodtech',
            mobile: 'nos-services/developpement-application-mobile',
            web: 'nos-services/developpement-site-web',
            blockchain: 'nos-services/developpement-blockchain',
            startup: 'nos-services/developpement-start-up',
        },
        ar: {
            rgpd: 'GDPR',
            form: 'form',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
    };
    return (
        <><Box
            backgroundColor='#0A0A0A'
            position="relative"
            overflow={'hidden'}
        >
            <Box className='footerontainer'>
            </Box>
            <Box backgroundColor='black'>
                <button className="arrow-up-container" onClick={scrollToTop}>
                    <img src={arrowUp} alt="arrow up" className="arrow-up" />
                </button>
                <img style={{ width: '100%' }} src={rectangle} alt='rectangle' />

                <Flex justifyContent="center" alignItems="center" direction="column" >
                    <ul className="footer-menu1" id='footermenu2' style={{ marginTop: '10px', marginBottom: '250px' }}>
                        <li className='logo'><Image id='logo' src={footerlogo} alt="Logo" style={{ marginRight: '15px', marginLeft: '25px' }} /></li>
                        <li className={`${isRTL ? 'li-rtl' : 'li'}`}>
                            <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')}</NavLink> - <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc16')}</NavLink>
                        </li>
                        <li className={`${isRTL ? 'li-rtl' : 'li'}`}>
                            <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>© 2024 Omniverselab </NavLink>-<NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc17')}</NavLink>
                        </li>
                    </ul>
                </Flex>
                <Flex mt='-250px' justifyContent="center" alignItems="center">
                    <Flex
                        className="footer-menu-res footer-menu"
                        align="center"
                        justify="center"
                        width="100%"
                        fontFamily="Poppins"
                        fontSize="16px"
                        fontWeight='200'
                        lineHeight="24px"
                        letterSpacing="0.1em"
                        textAlign="left"
                    >
                        <Flex flex="1" justify="flex-end">
                            <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')} - </NavLink>
                            <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc16')}</NavLink>
                        </Flex>
                        <Image src={footerlogo} alt="Logo" boxSize="60px" mx="25px" />
                        <Flex flex="1" justify="flex-start">
                            <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>&copy; 2024 Omniverselab - </NavLink>
                            <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc17')} </NavLink>
                        </Flex>
                    </Flex>
                </Flex>
                <Text id={`${isRTL ? 'footer-rtl' : 'nos-service'}`} display='flex' justifyContent='center' color='rgba(255, 255, 255, 0.7)' fontSize='16px' fontFamily='Poppins' lineHeight='24px' mt={10} fontWeight={200}>{t('Contact.title')}</Text>
                <Box className='footercontent-container'>
                    <Box className='footercontent' id={`${isRTL ? 'footer-rtl' : ''}`}>
                        <ul  >
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].fintech}`}>{t('Contact.items.item1')}</NavLink></li>
                            <li> <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].ia}`}>{t('Contact.items.item2')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].foodtech}`}>{t('Contact.items.item3')}</NavLink></li>
                        </ul>
                        <ul>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].mobile}`}>{t('Contact.items.item4')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].web}`}>{t('Contact.items.item5')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].blockchain}`}>{t('Contact.items.item6')}</NavLink></li>
                        </ul>
                        <ul>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].startup}`}>{t('Contact.items.item7')}</NavLink></li>
                        </ul>
                    </Box>
                </Box>
            </Box>
        </Box>
        </>
    )
}

export default Footer