import { useEffect } from 'react';
import { Box, HStack, Button, Text } from '@chakra-ui/react'
import articleImg from '../../../assets/images/Group-412.webp'
import logo from '../../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import './Article.css';
import arrow from '../../../assets/images/Arrow 2.svg';
import { NavLink } from 'react-router-dom';
import Content from './Content';
import Footer from './Footer';
import Logo from '../../Logo/Logo';
import Titles from '../../Titles'
function Aritcle() {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const paths = {
        en: {
            rgpd: 'GDPR',
            form: 'form',
            nosServices: 'our-services',
        },
        fr: {
            rgpd: 'RGPD',
            form: 'formulaire',
            nosServices: 'nos-services',
        },
        ar: {
            rgpd: 'GDPR',
            form: 'form',
            nosServices: 'our-services',
        },
    };
    return (

        <>
            <Titles title={t('Contact.items.item4')} />

            <Box
                bgImage={`url(${articleImg})`}
                bgPosition="center"
                bgSize="cover"
                height="auto"
                width="100%"
                position="relative"
                overflow={'hidden'}
            >
                <HStack display="flex" justifyContent="space-between">
                    <div className="header-content">
                        <NavLink to='/'>
                            <button className='fleche' id='fleche'><img src={arrow} alt='arrow' /></button>
                        </NavLink>
                        <div alt="logo" className="header-logo" id='second_logo' >
                            <Logo />
                        </div>
                    </div>
                    <Button className='contactButton'>
                        <NavLink id={`${isRTL ? 'titles-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].form}`}> {t('contact')}</NavLink>
                    </Button>
                </HStack>
                <div className='container2' style={{ display: 'flex' }}>
                    <NavLink to={`/${currentLang}`}>
                        <h4 className={isRTL ? 'letter-rtl2' : 'link1'}>{t('Form_page.section1.title1')}/ </h4>
                    </NavLink>
                    <NavLink to={`/${currentLang}/${paths[currentLang].nosServices}`}>
                        <h4 style={{ color: "rgba(255, 255, 255, 0.441)" }} className={isRTL ? 'letter-rtl1' : 'link2'}>
                            {t('Contact.title').toUpperCase()}
                        </h4>
                    </NavLink>
                    <h4 className={isRTL ? 'letter-rtl1' : 'link2'}>/ {t('Contact.items.item4')}</h4>
                </div>
                <div className={`${isRTL ? 'container_title' : 'article_container'}`}>
                    <h2 id={`${isRTL ? 'titles-rtl' : ''}`} className={`${isRTL ? 'content-rtl subtitle_blog' : 'subtitle_blog'}`} >
                        {t('Contact.items.item4')}
                    </h2>
                    <Text className={`${isRTL ? 'content-rtl h1-style2 fintech' : 'h1-style2 fintech'}`} id='title_blog' mb={50} >
                        {t('Mobile_section.title')}
                    </Text>
                </div>
            </Box>
            <Content />
            <Footer />
        </>
    )
}

export default Aritcle