import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "./components/Header/Header";
import Feature from "./components/Feature/Feature";
import Section from "./components/section/SectionTeam";
import Contact from "./components/ContactUs/Contact";
import FormPage from "./components/Form/FormPage";
import Gdpr from "./components/Gdpr/Gdpr";
import SendEmail from "./components/SendEmail/SendEmail";
import Blog from "./components/Blog/Blog";
import Fintech from "./components/Articles/Fintech/Aritcle";
import IA from "./components/Articles/IA/Aritcle";
import Foodetech from './components/Articles/FoodTech/Aritcle';
import Mobile from './components/Articles/Mobile/Aritcle';
import Web from './components/Articles/Web/Aritcle';
import Blockchain from './components/Articles/Blockchain/Aritcle';
import Startup from './components/Articles/Start_up/Aritcle';

function App() {
  const { i18n } = useTranslation();
  const supportedLangs = ['en', 'fr', 'ar'];
  const paths = {
    en: {
      omniverse: '/en',
      form: '/en/form',
      gdpr: '/en/GDPR',
      sendEmail: '/en/sent',
      blog: '/en/our-services',
      fintech: '/en/our-services/fintech-development',
      ia: '/en/our-services/ai-development',
      foodtech: '/en/our-services/foodtech-development',
      mobile: '/en/our-services/mobile-application-development',
      web: '/en/our-services/website-development',
      blockchain: '/en/our-services/blockchain-development',
      startup: '/en/our-services/startup-development',
    },
    fr: {
      omniverse: '/fr',
      form: '/fr/formulaire',
      gdpr: '/fr/RGPD',
      sendEmail: '/fr/envoye',
      blog: '/fr/nos-services',
      fintech: '/fr/nos-services/developpement-fintech',
      ia: '/fr/nos-services/developpement-ia',
      foodtech: '/fr/nos-services/developpement-foodtech',
      mobile: '/fr/nos-services/developpement-application-mobile',
      web: '/fr/nos-services/developpement-site-web',
      blockchain: '/fr/nos-services/developpement-blockchain',
      startup: '/fr/nos-services/developpement-start-up',
    },
    ar: {
      omniverse: '/ar',
      form: '/ar/form',
      gdpr: '/ar/GDPR',
      sendEmail: '/ar/sent',
      blog: '/ar/our-services',
      fintech: '/ar/our-services/fintech-development',
      ia: '/ar/our-services/ai-development',
      foodtech: '/ar/our-services/foodtech-development',
      mobile: '/ar/our-services/mobile-application-development',
      web: '/ar/our-services/website-development',
      blockchain: '/ar/our-services/blockchain-development',
      startup: '/ar/our-services/startup-development',
    }
  };

  const extractLanguageFromPath = (path) => {
    const segments = path.split('/');
    return supportedLangs.includes(segments[1]) ? segments[1] : null;
  };

  const updatePathWithLanguage = (lang, path) => {
    const currentPathWithoutLang = path.replace(/^\/(en|fr|ar)/, '');
    return `/${lang}${currentPathWithoutLang}`;
  };

  useEffect(() => {
    const path = window.location.pathname;
    let lang = extractLanguageFromPath(path);
    const browserMainLang = window.navigator.language.split('-')[0];
    const languageToSet = lang || (supportedLangs.includes(browserMainLang) ? browserMainLang : supportedLangs[0]);

    if (!lang) {
      const newPath = `/${languageToSet}${path}`;
      window.history.replaceState(null, "", newPath);
    }

    i18n.changeLanguage(languageToSet);

    const handleLanguageChange = () => {
      const newBrowserMainLang = window.navigator.language.split('-')[0];
      const newLanguageToSet = supportedLangs.includes(newBrowserMainLang) ? newBrowserMainLang : supportedLangs[0];
      if (i18n.language !== newLanguageToSet) {
        i18n.changeLanguage(newLanguageToSet);
        const newPath = updatePathWithLanguage(newLanguageToSet, window.location.pathname);
        window.history.replaceState(null, "", newPath);
      }
    };

    window.addEventListener("languagechange", handleLanguageChange);

    return () => {
      window.removeEventListener("languagechange", handleLanguageChange);
    };
  }, [i18n]);

  const LangRoutes = () => {
    const location = useLocation();
    const lang = extractLanguageFromPath(location.pathname) || window.navigator.language.split('-')[0];

    useEffect(() => {
      if (window.gtag) {
        window.gtag('config', 'G-0JSWMTQ98C', {
          page_path: location.pathname,
        });
      }
    }, [location]);

    return (
      <Routes>
        <Route path="/" element={<Navigate replace to={`/${supportedLangs.includes(lang) ? lang : supportedLangs[0]}`} />} />
        <Route path={paths[lang].omniverse} element={<Omniverse />} />
        <Route path={paths[lang].form} element={<FormPage />} />
        <Route path={paths[lang].gdpr} element={<Gdpr />} />
        <Route path={paths[lang].sendEmail} element={<SendEmail />} />
        <Route path={paths[lang].blog} element={<Blog />} />
        <Route path={paths[lang].fintech} element={<Fintech />} />
        <Route path={paths[lang].ia} element={<IA />} />
        <Route path={paths[lang].foodtech} element={<Foodetech />} />
        <Route path={paths[lang].mobile} element={<Mobile />} />
        <Route path={paths[lang].web} element={<Web />} />
        <Route path={paths[lang].blockchain} element={<Blockchain />} />
        <Route path={paths[lang].startup} element={<Startup />} />
        <Route path="*" element={<Navigate replace to={paths[lang]?.omniverse || paths[supportedLangs[0]].omniverse} />} />
      </Routes>
    );
  };

  return (
    <Router>
      <LangRoutes />
    </Router>
  );
}

function Omniverse() {
  return (
    <>
      <Header />
      <Feature />
      <Section />
      <Contact />
    </>
  );
}

export default App;
