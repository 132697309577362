import React, { useState } from 'react';
import './FormPage.css';
import rectangle from '../../assets/images/Rectangle.webp';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import footer from '../../assets/images/Group 59 (2).webp';
import arrowUp from '../../assets/images/Group 388 (1).png'
import footermobile from '../../assets/images/Group 73 (5).webp'
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logofooter.svg';
import { NavLink, useNavigate } from 'react-router-dom';

function Contact() {
    const { t, i18n } = useTranslation();
    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [surname, setSurname] = useState('');
    const [country, setCountry] = useState('');
    const [company, setCompany] = useState('');
    const [service, setService] = useState('');
    const [budget, setBudget] = useState('');
    const [message, setMessage] = useState('');
    const [phone, setNumber] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';

    const validateForm = () => {
        const errors = {};
        if (!name) errors.name = t('Form_page.errors.nameRequired');
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!mail) {
            errors.mail = t('Form_page.errors.emailRequired');
        } else if (!emailRegex.test(mail)) {
            errors.mail = t('Form_page.errors.emailInvalid');
        }
        if (!message) errors.message = t('Form_page.errors.messageRequired');
        if (!budget) errors.budget = t('Form_page.errors.budgetRequired');
        if (!company) errors.company = t('Form_page.errors.companyRequired');
        if (!phone) errors.phone = t('Form_page.errors.phoneRequired');

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return
        console.log('Form submitted');
        try {
            await fetch(process.env.REACT_APP_EMAIL_REST_API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    service_id: process.env.REACT_APP_EMAIL_SERVICE_ID,
                    template_id: process.env.REACT_APP_EMAIL_TEMPLATE_ID,
                    user_id: process.env.REACT_APP_EMAIL_USER_ID,
                    template_params: {
                        name: name,
                        mail: mail,
                        country: country,
                        company: company,
                        budget: budget,
                        message: message,
                        phone: phone,
                    }
                })
            });
            setName('');
            setMail('');
            setCountry('');
            setCompany('');
            setBudget('');
            setMessage('');
            setNumber('');
            navigate(`/${currentLang}/${paths[currentLang].sendEmail}`);

        } catch (err) {
            console.error(err);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const paths = {
        en: {
            rgpd: 'GDPR',
            form: 'form',
            sendEmail: 'sent',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
        fr: {
            rgpd: 'RGPD',
            form: 'formulaire',
            sendEmail: 'envoye',
            nosServices: 'nos-services',
            fintech: 'nos-services/developpement-fintech',
            ia: 'nos-services/developpement-ia',
            foodtech: 'nos-services/developpement-foodtech',
            mobile: 'nos-services/developpement-application-mobile',
            web: 'nos-services/developpement-site-web',
            blockchain: 'nos-services/developpement-blockchain',
            startup: 'nos-services/developpement-start-up',
        },
        ar: {
            rgpd: 'GDPR',
            form: 'form',
            sendEmail: 'sent',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
    };
    return (
        <>
            <Box
                bgImage={`url(${rectangle})`}
                bgPosition="center"
                bgSize="cover"
                height="auto"
                width="100%"
                zIndex='-1'
                paddingTop="10px"
                overflow={'hidden'}
            >
                <div className={isRTL ? 'card-rtl' : 'card'}>
                    <div className="input-row">
                        <div className="input-group order-name">
                            <label htmlFor="name">{t('Form_page.section3.item1')} </label>
                            <input className={isRTL ? 'input-rtl' : ''} id="name" type="text" placeholder="John Doe" value={name} onChange={(e) => setName(e.target.value)} />
                            {errors.name && <span className="error">{errors.name}</span>}
                        </div>
                        <div className="input-group order-mail" id="input-group">
                            <label htmlFor="mail">{t('Form_page.section3.item4')} </label>
                            <input className={isRTL ? 'input-rtl' : ''} id="mail" type="mail" placeholder="contact@gmail.com" value={mail} onChange={(e) => setMail(e.target.value)} />
                            {errors.mail && <span className="error">{errors.mail}</span>}
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="input-group order-surname">
                            <div className='order-country'>
                                <label htmlFor="country" >{t('Form_page.section3.item3')} </label>
                                <input className={isRTL ? 'input-rtl' : ''} id="country" type="text" placeholder="United States" value={country} onChange={(e) => setCountry(e.target.value)} />
                            </div>
                        </div>
                        <div className="input-group height-input order-phone" id="input-group">
                            <label id='phone' style={{ marginBottom: '-8px' }}>{t('Form_page.section3.item5')} </label>
                            <input className={isRTL ? 'input-rtl' : ''} style={{ height: '23px' }} type="number" placeholder="(123) 456-7890" onChange={(e) => setNumber(e.target.value)} />
                            {errors.phone && <span className="error">{errors.phone}</span>}
                        </div>
                    </div>
                    <div className="input-row">
                        <div className="input-group">
                            <label id='inputs' htmlFor="name">{t('Form_page.section4.item1')} </label>
                            <input className={isRTL ? 'input-rtl' : ''} id="name" type="text" placeholder="Omniverse Lab" onChange={(e) => setCompany(e.target.value)} />
                            {errors.company && <span className="error">{errors.company}</span>}
                            <label id='inputs' style={{ marginTop: '30px' }}>{t('Form_page.section4.item3')}</label>
                            <input className={isRTL ? 'input-rtl' : ''} id="name" type="number" placeholder="~ €" onChange={(e) => setBudget(e.target.value)} />
                            {errors.budget && <span className="error">{errors.budget}</span>}
                        </div>
                        <div className="input-group brief" id="input-group">
                            <label id='inputs'  >{t('Form_page.section4.item4')} </label>
                            <textarea className={isRTL ? 'input-rtl' : ''} id="mail" type="text" placeholder="a quick project description, or your questions, ..." onChange={(e) => setMessage(e.target.value)} />
                            {errors.message && <span className="error">{errors.message}</span>}
                        </div>
                    </div>
                </div>
                <div id='style-btn' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '-10px', zIndex: '100' }}>
                    <button className={isRTL ? 'button-rtl' : ''} type='submit' onClick={onSubmit}>{t('Form_page.section4.button')}</button>
                </div>
                <div className="footer-container" style={{ position: 'relative' }}>
                    <img src={footer} alt='footer' className="footer-background" id='bg1' />
                    <button style={{ bottom: '90px' }} className="arrow-up-container" onClick={scrollToTop}>
                        <img src={arrowUp} alt="arrow up" className="arrow-up" />
                    </button>
                    <img src={footermobile} alt='footer' className="footer-background" id='bg2' />
                    <div className='box2' style={{ position: 'absolute', top: '38%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1' }}>
                        <h1 id={isRTL ? 'footer-title-rtl1' : 'footer-title1'}>{t('Form_page.section5.title')}</h1>
                        <h2 id={isRTL ? 'footer-title-rtl2' : 'footer-title2'}>{t('Form_page.section5.subtitle')} </h2>
                    </div>
                    <Flex justifyContent="center" alignItems="center" direction="column">
                        <ul className="footer-menu1" id='footermenu2'>
                            <li className='logo'><Image id='logo' src={logo} alt="Logo" style={{ marginRight: '15px', marginLeft: '25px' }} /></li>
                            <li className={`${isRTL ? 'li-rtl' : 'li'}`}> <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')} </NavLink> - <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc16')}</NavLink> </li>
                            <li className={`${isRTL ? 'li-rtl' : 'li'}`}> <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>© 2024 Omniverselab </NavLink>-<NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}> {t('desc17')} </NavLink></li>
                        </ul>
                    </Flex>
                    <Flex justifyContent="center" alignItems="center">
                        <Flex
                            className="footer-menu-res"
                            id='footermenu'
                            align="center"
                            justify="center"
                            width="100%"
                            fontFamily="Poppins"
                            fontSize="16px"
                            fontWeight='200'
                            lineHeight="24px"
                            letterSpacing="0.1em"
                            textAlign="left"
                        >
                            <Flex flex="1" justify="flex-end">
                                <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')} - </NavLink>
                                <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc16')}</NavLink>
                            </Flex>
                            <Image src={logo} alt="Logo" boxSize="60px" mx="25px" />
                            <Flex flex="1" justify="flex-start">
                                <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>&copy; 2024 Omniverselab - </NavLink>
                                <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc17')} </NavLink>
                            </Flex>
                        </Flex>
                    </Flex>
                </div>
                <Text id={`${isRTL ? 'footer-rtl' : 'nos-service'}`} display='flex' justifyContent='center' color='rgba(255, 255, 255, 0.7)' fontSize='16px' fontFamily='Poppins' lineHeight='24px' mt={10} fontWeight={200}>{t('Contact.title')}</Text>
                <Box className='footercontent-container'>
                    <Box className='footercontent' id={`${isRTL ? 'footer-rtl' : ''}`}>
                        <ul  >
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].fintech}`}>{t('Contact.items.item1')}</NavLink></li>
                            <li> <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].ia}`}>{t('Contact.items.item2')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].foodtech}`}>{t('Contact.items.item3')}</NavLink></li>
                        </ul>
                        <ul>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].mobile}`}>{t('Contact.items.item4')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].web}`}>{t('Contact.items.item5')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].blockchain}`}>{t('Contact.items.item6')}</NavLink></li>
                        </ul>
                        <ul>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].startup}`}>{t('Contact.items.item7')}</NavLink></li>
                        </ul>
                    </Box>
                </Box>
            </Box >
        </>
    );
}

export default Contact;