import React, { useState, useEffect } from 'react';
import blog from '../../assets/images/Group-395-_1.webp';
import { Box, HStack, Button } from '@chakra-ui/react';
import logo from '../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import './Blog.css';
import arrow from '../../assets/images/Arrow 2.svg';
import { NavLink } from 'react-router-dom';
import CardArticle from '../CardArticle/CardArticle';
import Logo from '../Logo/Logo';
import Titles from '../Titles'
function Blog() {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';
    const [selectedCategory, setSelectedCategory] = useState(t('Blog.menu.development'));

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        setSelectedCategory(t('Blog.menu.development'));
    }, [i18n.language]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const paths = {
        en: {
            rgpd: 'GDPR',
            form: 'form',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
        fr: {
            rgpd: 'RGPD',
            form: 'formulaire',
            nosServices: 'nos-services',
            fintech: 'nos-services/developpement-fintech',
            ia: 'nos-services/developpement-ia',
            foodtech: 'nos-services/developpement-foodtech',
            mobile: 'nos-services/developpement-application-mobile',
            web: 'nos-services/developpement-site-web',
            blockchain: 'nos-services/developpement-blockchain',
            startup: 'nos-services/developpement-start-up',
        },
        ar: {
            rgpd: 'GDPR',
            form: 'form',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
    };
    return (
        <>
            <Titles title={t('Contact.title')} />

            <Box
                bgImage={`url(${blog})`}
                bgPosition="center"
                bgSize="cover"
                height="auto"
                width="100%"
                position="relative"
                overflow={'hidden'}
            >
                <HStack display="flex" justifyContent="space-between">
                    <div className="header-content">
                        <NavLink to='/'>
                            <button className='fleche' id='fleche'><img src={arrow} alt='arrow' /></button>
                        </NavLink>
                        <div alt="logo" className="header-logo" id='second_logo' >
                            <Logo />
                        </div>
                    </div><NavLink to={`/${currentLang}/${paths[currentLang].form}`}>
                        <Button className='contactButton'>
                            {t('contact')}
                        </Button>
                    </NavLink>
                </HStack>
                <div className='container2' style={{ display: 'flex' }}>
                    <NavLink to={`/${currentLang}`}>
                        <h4 className={isRTL ? 'letter-rtl2' : 'link1'}>{t('Form_page.section1.title1')}/ </h4>
                    </NavLink>
                    <h4 style={{ width: '200px' }} className={isRTL ? 'letter-rtl1' : 'link2'}>
                        {t('Contact.title').toUpperCase()}
                    </h4>
                </div>
                <div className={`${isRTL ? 'container-rtl blog_container' : 'blog_container'}`}>
                    <h1 className={`${isRTL ? 'h1-style2 titles-rtl' : 'h1-style2'}`} id='title_blog' style={{ textTransform: 'uppercase' }}>{t('Contact.title')}</h1>
                    <p className={`${isRTL ? 'p1-style titles-rtl' : 'p1-style'}`} id='p_blog'><span>{t('Blog.decription1')}</span> {t('Blog.decription2')} <span> {t('Blog.decription3')}</span> </p>
                </div>
                <ul className="category-list">
                    {[t('Blog.menu.development'), t('Blog.menu.ia'), t('Blog.menu.blockchain')].map((category) => (
                        <li
                            id={`${isRTL ? 'titles-rtl' : ''}`}
                            key={category}
                            className={selectedCategory === category ? 'selected' : ""}
                            onClick={() => handleCategoryClick(category)}
                        >
                            {category}
                        </li>
                    ))}
                </ul>
            </Box>
            <CardArticle selectedCategory={selectedCategory} />
        </>
    );
}

export default Blog;
