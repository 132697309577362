import React from 'react'
import './logo.css'
function logo() {
    return (
        <div>
            <svg className='test ' width="142" height="55" viewBox="0 0 240 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M56.0748 14.3126C58.273 10.653 56.9196 5.82454 52.5691 5.7195C52.0361 5.70663 51.5011 5.71891 50.9659 5.75674C47.798 5.9807 44.7674 7.09004 42.2574 8.94449C39.7474 10.7989 37.8707 13.3152 36.8646 16.1751C35.8586 19.035 35.7684 22.11 36.6054 25.0114C37.4424 27.9127 39.169 30.5101 41.5669 32.475C43.9648 34.4399 46.9264 35.6841 50.077 36.0503C53.2276 36.4164 56.4258 35.8881 59.2672 34.5321C59.642 34.3532 60.0083 34.161 60.3656 33.9561C64.235 31.7364 62.9181 26.7547 59.0672 24.6855L58.9037 24.5977C55.1432 22.577 53.8767 17.9722 56.0748 14.3126V14.3126Z" fill="white" />
                <path d="M28.7457 48.7959C28.9409 51.853 31.7081 54.2949 34.3731 52.8009C34.7562 52.5862 35.1272 52.3503 35.4843 52.094C37.3319 50.7675 38.7221 48.9528 39.479 46.8793C40.2359 44.8058 40.3257 42.5666 39.7368 40.445C39.148 38.3233 37.907 36.4144 36.1708 34.9596C34.4347 33.5049 32.2813 32.5697 29.983 32.2722C27.6848 31.9748 25.3448 32.3285 23.2591 33.2886C21.1733 34.2487 19.4355 35.7721 18.2653 37.6661C18.1404 37.8682 18.0226 38.0736 17.9119 38.2819C16.3972 41.133 19.3057 43.7796 22.5386 43.595L22.8547 43.5769C25.9131 43.4022 28.5505 45.7388 28.7457 48.7959V48.7959Z" fill="url(#paint0_linear_4_111)" />
                <path d="M9.22966 16.745C4.96651 17.1395 1.74877 21.1653 4.35087 24.591C4.54235 24.8431 4.74265 25.0899 4.95157 25.331C6.89635 27.5753 9.50108 29.2237 12.4364 30.0678C15.3717 30.9119 18.5057 30.9138 21.4421 30.0733C24.3786 29.2327 26.9855 27.5874 28.9333 25.3455C30.8812 23.1036 32.0824 20.3657 32.385 17.4781C32.6877 14.5904 32.0782 11.6828 30.6338 9.12273C29.1893 6.56271 26.9746 4.46532 24.2698 3.09578C23.7489 2.83202 23.2143 2.59754 22.6688 2.39286C18.8573 0.962725 15.7764 4.69743 16.1935 8.72964V8.72964C16.6106 12.7619 13.6765 16.3334 9.64005 16.707L9.22966 16.745Z" fill="url(#paint1_linear_4_111)" />
                <ellipse cx="34.0887" cy="3.55909" rx="4.26114" ry="3.55885" fill="url(#paint2_linear_4_111)" />
                <g filter="url(#filter0_d_4_111)">
                    <path d="M79.9337 30.7573C78.9843 30.7573 78.1356 30.5847 77.3876 30.2395C76.654 29.8798 76.0354 29.398 75.532 28.7938C75.0285 28.1896 74.6473 27.5064 74.3884 26.744C74.1295 25.9672 74 25.1617 74 24.3274C74 23.4067 74.1582 22.5005 74.4747 21.6087C74.8055 20.7168 75.2587 19.8897 75.834 19.1273C76.4238 18.3505 77.1071 17.6745 77.8839 17.0991C78.675 16.5093 79.5309 16.049 80.4515 15.7181C81.3721 15.3873 82.3287 15.2219 83.3213 15.2219C84.2707 15.2219 85.1122 15.4017 85.8458 15.7613C86.5794 16.1065 87.1979 16.5812 87.7014 17.1854C88.2049 17.7895 88.5789 18.48 88.8234 19.2568C89.0823 20.0192 89.2118 20.8175 89.2118 21.6518C89.2118 22.5724 89.0464 23.4787 88.7155 24.3705C88.3991 25.248 87.9531 26.0751 87.3777 26.8519C86.8024 27.6143 86.1263 28.2903 85.3495 28.8801C84.5727 29.4699 83.724 29.9302 82.8034 30.261C81.8828 30.5919 80.9262 30.7573 79.9337 30.7573ZM80.7536 27.0245C81.4441 27.0245 82.0482 26.8663 82.5661 26.5498C83.0839 26.2333 83.5155 25.8234 83.8607 25.3199C84.2203 24.8021 84.4864 24.2482 84.659 23.6585C84.8317 23.0687 84.918 22.4933 84.918 21.9323C84.918 21.4001 84.8317 20.911 84.659 20.4651C84.5008 20.0048 84.2419 19.638 83.8823 19.3646C83.5226 19.0913 83.048 18.9547 82.4582 18.9547C81.7821 18.9547 81.1851 19.1129 80.6673 19.4294C80.1494 19.7315 79.7179 20.1342 79.3727 20.6377C79.0274 21.1412 78.7613 21.6878 78.5743 22.2775C78.4017 22.8673 78.3154 23.4427 78.3154 24.0037C78.3154 24.5359 78.3945 25.0322 78.5527 25.4925C78.7254 25.9528 78.9915 26.3268 79.3511 26.6145C79.7107 26.8878 80.1782 27.0245 80.7536 27.0245Z" fill="white" />
                    <path d="M94.0295 15.3082H98.6254L100.028 22.5149L104.516 15.3082C104.516 15.3082 107.304 15.3082 109.09 15.3082C107.818 21.2909 105.832 30.6278 105.832 30.6278H101.625L103.329 22.558L99.92 28.2328H97.6976L96.7051 22.558L94.9789 30.6278H90.7714L94.0295 15.3082Z" fill="white" />
                    <path d="M113.24 15.3082C114.521 15.3082 116.519 15.3082 116.519 15.3082L120.403 23.0975L122.043 15.3082H126.251L122.993 30.6278H119.67L115.786 23.0975L114.189 30.6278H109.982C109.982 30.6278 111.299 24.4339 112.143 20.4651C112.571 18.4512 112.811 17.3221 113.24 15.3082Z" fill="white" />
                    <path d="M130.385 15.3082H134.593L131.334 30.6278H127.127L130.385 15.3082Z" fill="white" />
                    <path d="M137.19 15.3082H141.635L142.153 25.3415L146.9 15.3082H151.323L142.779 30.6278H139.283L137.19 15.3082Z" fill="white" />
                    <path d="M154.077 15.3082H164.304L163.528 18.9763H157.508L157.054 21.134H162.19L161.456 24.5431H156.321L155.803 26.9598H162.017L161.24 30.6278H150.819L154.077 15.3082Z" fill="white" />
                    <path d="M164.74 30.6278L167.998 15.3082H174.73C175.565 15.3082 176.269 15.5239 176.845 15.9555C177.435 16.3726 177.88 16.9193 178.183 17.5953C178.499 18.2714 178.657 18.9835 178.657 19.7315C178.657 20.2349 178.585 20.7384 178.441 21.2418C178.298 21.7309 178.089 22.2056 177.816 22.6659C177.557 23.1262 177.24 23.5578 176.866 23.9605C176.492 24.3489 176.075 24.6942 175.615 24.9962L177.449 30.6278H172.702L171.213 25.9241H169.94L168.948 30.6278H164.74ZM173.069 22.256C173.184 22.256 173.313 22.2056 173.457 22.1049C173.601 22.0042 173.738 21.8604 173.867 21.6734C173.997 21.4864 174.104 21.2634 174.191 21.0045C174.277 20.7456 174.32 20.4651 174.32 20.163C174.32 19.9041 174.284 19.6883 174.212 19.5157C174.155 19.3431 174.076 19.2136 173.975 19.1273C173.874 19.0266 173.766 18.9763 173.651 18.9763H171.429L170.738 22.256H173.069Z" fill="white" />
                    <path d="M185.698 30.822C184.648 30.822 183.576 30.6566 182.483 30.3258C181.39 29.9949 180.462 29.5634 179.7 29.0311L182.289 25.3415C182.275 25.3415 182.375 25.4278 182.591 25.6004C182.821 25.773 183.138 25.9672 183.541 26.183C183.958 26.3988 184.425 26.5929 184.943 26.7656C185.461 26.9382 186 27.0245 186.561 27.0245C186.892 27.0245 187.137 26.9885 187.295 26.9166C187.453 26.8303 187.554 26.7296 187.597 26.6145C187.655 26.4851 187.683 26.3556 187.683 26.2261C187.683 25.9672 187.547 25.7586 187.273 25.6004C187 25.4278 186.648 25.2767 186.216 25.1473C185.799 25.0034 185.353 24.838 184.878 24.651C184.245 24.3921 183.72 24.1116 183.303 23.8095C182.886 23.493 182.57 23.1262 182.354 22.7091C182.152 22.2919 182.052 21.81 182.052 21.2634C182.052 20.2997 182.239 19.4438 182.613 18.6958C183.001 17.9334 183.519 17.2933 184.166 16.7754C184.814 16.2576 185.533 15.862 186.324 15.5887C187.115 15.3154 187.921 15.1787 188.741 15.1787C189.46 15.1787 190.136 15.2722 190.769 15.4592C191.402 15.6318 191.984 15.8476 192.517 16.1065C193.049 16.3654 193.509 16.61 193.897 16.8401L191.351 20.314C191.38 20.314 191.294 20.2421 191.092 20.0983C190.891 19.9544 190.618 19.7962 190.273 19.6236C189.927 19.4366 189.539 19.2711 189.107 19.1273C188.676 18.9835 188.244 18.9115 187.813 18.9115C187.611 18.9115 187.417 18.9475 187.23 19.0194C187.058 19.077 186.921 19.1705 186.82 19.2999C186.72 19.4294 186.669 19.602 186.669 19.8178C186.669 20.0335 186.763 20.2133 186.95 20.3572C187.151 20.501 187.424 20.6377 187.77 20.7672C188.115 20.8822 188.51 21.0261 188.956 21.1987C189.647 21.4576 190.251 21.7453 190.769 22.0618C191.301 22.3639 191.711 22.745 191.999 23.2054C192.286 23.6657 192.43 24.2554 192.43 24.9747C192.43 26.0679 192.229 26.9885 191.826 27.7365C191.423 28.4845 190.891 29.0887 190.229 29.549C189.568 29.9949 188.841 30.3186 188.05 30.52C187.259 30.7213 186.475 30.822 185.698 30.822Z" fill="white" />
                    <path d="M197.969 15.3082H208.197L207.42 18.9763H201.4L200.947 21.134H206.082L205.348 24.5431H200.213L199.695 26.9598H205.909L205.133 30.6278H194.711L197.969 15.3082Z" fill="white" />
                </g>
                <path d="M214.294 14.4465H216.273V16H212.241V9.45292H214.294V14.4465ZM222.042 15.0105H219.86L219.536 16H217.382L219.777 9.45292H222.144L224.53 16H222.366L222.042 15.0105ZM221.543 13.4662L220.951 11.6445L220.359 13.4662H221.543ZM230.304 12.6432C230.699 12.7357 231.004 12.9268 231.22 13.2166C231.442 13.5002 231.552 13.83 231.552 14.206C231.552 14.7794 231.361 15.2232 230.979 15.5376C230.603 15.8459 230.061 16 229.352 16H225.921V9.45292H229.259C229.925 9.45292 230.449 9.5978 230.831 9.88754C231.213 10.1773 231.405 10.5996 231.405 11.1544C231.405 11.5366 231.303 11.8603 231.099 12.1254C230.902 12.3843 230.637 12.5569 230.304 12.6432ZM227.974 12.0052H228.769C228.954 12.0052 229.09 11.9682 229.176 11.8942C229.268 11.8202 229.315 11.7062 229.315 11.5521C229.315 11.3918 229.268 11.2746 229.176 11.2007C229.09 11.1205 228.954 11.0804 228.769 11.0804H227.974V12.0052ZM228.908 14.354C229.093 14.354 229.228 14.3201 229.315 14.2523C229.407 14.1783 229.453 14.0612 229.453 13.9009C229.453 13.5865 229.271 13.4293 228.908 13.4293H227.974V14.354H228.908Z" fill="white" fill-opacity="0.48" />
                <path d="M112.097 21.2458L113.66 15.3821H107.798C111.237 15.3821 112.097 19.2912 112.097 21.2458Z" fill="white" />
                <path d="M113.66 15.3821L112.097 21.2458C112.097 19.2912 111.237 15.3821 107.798 15.3821C104.359 15.3821 110.794 15.3821 114.441 15.3821" stroke="white" stroke-width="0.175912" stroke-linecap="round" />
                <path d="M193.602 16.8479L189.791 19.1934L198 24.1776L200.052 20.6593L193.602 16.8479Z" fill="white" stroke="white" stroke-width="0.293186" stroke-linecap="round" />
                <defs>
                    <filter id="filter0_d_4_111" x="71.5341" y="13.9457" width="139.129" height="20.5752" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="1.23297" />
                        <feGaussianBlur stdDeviation="1.23297" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.1425 0 0 0 0 0.1425 0 0 0 0 0.1425 0 0 0 0.2 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4_111" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4_111" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_4_111" x1="40.0997" y1="42.5919" x2="16.695" y2="44.0863" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#8176FF" />
                        <stop offset="1" stop-color="#8176FF" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_4_111" x1="18.4593" y1="30.6326" x2="15.7539" y2="1.39945" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#8176FF" />
                        <stop offset="1" stop-color="#8176FF" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_4_111" x1="24.3289" y1="-6.5" x2="33.9123" y2="7.24091" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A39BFF" />
                        <stop offset="0.188541" stop-color="#786CFF" stop-opacity="0.8375" />
                        <stop offset="0.291667" stop-color="#1D10B2" stop-opacity="0.708333" />
                        <stop offset="0.547916" stop-color="#CCC8FF" stop-opacity="0.843444" />
                        <stop offset="0.777083" stop-color="#5649F0" stop-opacity="0.906699" />
                        <stop offset="1" stop-color="#A39BFF" />
                    </linearGradient>
                </defs>
            </svg>

        </div>
    )
}

export default logo