import "./Gdpr.css"
import { useTranslation } from 'react-i18next'
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import planaite from '../../assets/images/planaite.webp';
import React, { useEffect } from 'react';
import logofooter from '../../assets/images/logofooter.svg';
import arrow from '../../assets/images/Arrow 2.svg';
import { NavLink } from 'react-router-dom';
import rectangle from '../../assets/images/Rectangle 38.svg';
import Logo from '../Logo/Logo';
import Titles from '../Titles'

function Gdpr() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';

    const paths = {
        en: {
            rgpd: 'GDPR',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
        fr: {
            rgpd: 'RGPD',
            nosServices: 'nos-services',
            fintech: 'nos-services/developpement-fintech',
            ia: 'nos-services/developpement-ia',
            foodtech: 'nos-services/developpement-foodtech',
            mobile: 'nos-services/developpement-application-mobile',
            web: 'nos-services/developpement-site-web',
            blockchain: 'nos-services/developpement-blockchain',
            startup: 'nos-services/developpement-start-up',
        },
        ar: {
            rgpd: 'GDPR',
            form: 'form',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
    };

    return (
        <>
            <Titles title={t('Form_page.section1.title3')} />
            <Box
                bgImage={`url(${planaite})`}
                bgPosition="center"
                bgSize="cover"
                height="auto"
                width="100%"
                position="relative"
                overflow={'hidden'}
            >
                <div className="header-content">
                    <NavLink to='/'> <button className='fleche' id='fleche'><img src={arrow} alt='arrow' /></button></NavLink>
                    <div alt="logo" className="header-logo" id='second_logo' >
                        <Logo />
                    </div>
                </div>
                <div className='container2' style={{ display: 'flex' }}>
                    <NavLink to='/'><h4 className={isRTL ? 'letter-rtl2' : 'link1'}>{t('Form_page.section1.title1')}/ </h4></NavLink>
                    <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}> <h4 className='link2'> {t('Form_page.section1.title3')}</h4></NavLink>
                </div>
                <div className={isRTL ? 'rgpd-page-container-rtl' : 'rgpd-page-container'} style={{ padding: '30px 20px 20px 30px' }}>
                    <div className={isRTL ? 'p2-style-rtl' : 'p-14 leading-8 p2-style'}>
                        <h3 className={isRTL ? 'title-rtl' : 'text-6xl font-medium mb-10 h1-rgpd'} >{t("cookiesPage.item_1.title")}</h3>
                        <p>{t("cookiesPage.item_1.description")}</p>
                    </div>
                    <div className={isRTL ? 'p2-style-rtl' : 'p-14 leading-8 p2-style'}>
                        <h3 className={isRTL ? 'title-rtl' : 'text-6xl font-medium mb-10 h1-rgpd'} >{t("cookiesPage.item_2.title")}</h3>
                        <div>
                            <span className='underline' style={{ textDecorationLine: 'underline' }}>{t("cookiesPage.item_2.subtitle")}</span>
                            <p>{t("cookiesPage.item_2.description")}</p>
                        </div>
                        <div >
                            <span className='underline' style={{ textDecorationLine: 'underline' }}>{t("cookiesPage.item_2.section_1.title")}</span>
                            <p className="">
                                {t("cookiesPage.item_2.section_1.description")}
                                <ul className={isRTL ? 'list-disc rtl pl-14 mt-5 decorated-list' : 'list-disc pl-14 mt-5 decorated-list'}>
                                    <li className={isRTL ? 'p2-style-rtl li-style' : 'p2-style li-style'}>
                                        {t("cookiesPage.item_2.section_1.listItems.list_1")}
                                    </li>
                                    <li className={isRTL ? 'p2-style-rtl li-style' : 'p2-style li-style'}>
                                        {t("cookiesPage.item_2.section_1.listItems.list_2")}
                                    </li>
                                    <li className={isRTL ? 'p2-style-rtl li-style' : 'p2-style li-style'}>
                                        {t("cookiesPage.item_2.section_1.listItems.list_3")}
                                    </li>
                                    <li className={isRTL ? 'p2-style-rtl li-style' : 'p2-style li-style'}>
                                        {t("cookiesPage.item_2.section_1.listItems.list_4")}
                                    </li>
                                    <li className={isRTL ? 'p2-style-rtl li-style' : 'p2-style li-style'}>
                                        {t("cookiesPage.item_2.section_1.listItems.list_5")}
                                    </li>
                                </ul>

                            </p>
                            <p className={isRTL ? 'p2-style-rtl' : 'p2-style li-style'}> {t("cookiesPage.item_2.section_1.secondExplication")}</p>
                        </div>
                        <div className='mt-10'>
                            <span className='underline mb-5' style={{ textDecorationLine: 'underline' }}>{t("cookiesPage.item_2.section_2.title")}</span>
                            <p>{t("cookiesPage.item_2.section_2.description")}</p>
                        </div>
                        <div className='mt-10'>
                            <span className='underline mb-5' style={{ textDecorationLine: 'underline' }}>{t("cookiesPage.item_2.section_3.title")}</span>
                            <p>{t("cookiesPage.item_2.section_3.description")}</p>
                        </div>
                        <div className='mt-10'>
                            <span className='underline mb-5' style={{ textDecorationLine: 'underline' }}>{t("cookiesPage.item_2.section_4.title")}</span>
                            <p>{t("cookiesPage.item_2.section_4.description")}</p>
                        </div>
                        <div className='mt-10'>
                            <span className='underline mb-5' style={{ textDecorationLine: 'underline' }}>{t("cookiesPage.item_2.section_5.title")}</span>
                            <p>{t("cookiesPage.item_2.section_5.description")}</p>
                        </div>
                        <div className='mt-10'>
                            <span className='underline mb-5' style={{ textDecorationLine: 'underline' }}>{t("cookiesPage.item_2.section_6.title")}</span>
                            <p>{t("cookiesPage.item_2.section_6.description")}</p>
                            <ul className='list-disc pl-14 mt-5 mb-5 decorated-list'>
                                <li className={isRTL ? 'p2-style-rtl li-style' : 'p2-style li-style'}>{t("cookiesPage.item_2.section_6.listItems.list_1")}</li>
                                <li className={isRTL ? 'p2-style-rtl li-style' : 'p2-style li-style'}>{t("cookiesPage.item_2.section_6.listItems.list_2")}</li>
                            </ul>
                            <p> {t("cookiesPage.item_2.section_6.secondExplication")}</p>
                        </div>
                        <div className='mt-10'>
                            <span className='underline mb-5' style={{ textDecorationLine: 'underline' }}>{t("cookiesPage.item_2.section_7.title")}</span>
                            <p>{t("cookiesPage.item_2.section_7.description")}</p>
                        </div>
                    </div>
                </div>
            </Box>

            <Box
                backgroundColor='black'
                bgPosition="center"
                bgSize="cover"
                height="auto"
                width="100%"
                position="relative"
                overflow={'hidden'}>
                <Image width='100%' src={rectangle} alt='rectangle' />
                <Flex justifyContent="center" alignItems="center" direction="column">
                    <ul className="footer-menu1">
                        <li className='logo'><Image src={logofooter} alt="Logo" boxSize="80px" style={{ marginRight: '15px', marginLeft: '25px', marginTop: '30px' }} /></li>
                        <li className={`${isRTL ? 'li-rtl' : 'li'}`}><NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')}</NavLink>  -  <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}> {t('desc16')}</NavLink></li>
                        <li className={`${isRTL ? 'li-rtl' : 'li'}`}><NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>© 2024 Omniverselab</NavLink> - <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc17')}</NavLink> </li>
                    </ul>
                </Flex>
                <Flex justifyContent="center" alignItems="center">
                    <Flex
                        className="footer-menu-res"

                        id='footermenu'
                        align="center"
                        justify="center"
                        width="100%"
                        fontFamily="Poppins"
                        fontSize="16px"
                        fontWeight='200'
                        lineHeight="24px"
                        letterSpacing="0.1em"
                        textAlign="left"
                    >
                        <Flex flex="1" justify="flex-end">
                            <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')}</NavLink> -{' '}
                            <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc16')}</NavLink>
                        </Flex>
                        <Image src={logofooter} alt="Logo" boxSize="60px" mx="25px" />
                        <Flex flex="1" justify="flex-start">
                            <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>&copy; 2024 Omniverselab</NavLink> -{' '}
                            <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc17')}</NavLink>
                        </Flex>
                    </Flex>
                </Flex>
                <Text id={`${isRTL ? 'footer-rtl' : 'nos-service'}`} display='flex' justifyContent='center' color='rgba(255, 255, 255, 0.7)' fontSize='16px' fontFamily='Poppins' lineHeight='24px' mt={10} fontWeight={200}>{t('Contact.title')}</Text>
                <Box className='footercontent-container'>
                    <Box className='footercontent' id={`${isRTL ? 'footer-rtl' : ''}`}>
                        <ul  >
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].fintech}`}>{t('Contact.items.item1')}</NavLink></li>
                            <li> <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].ia}`}>{t('Contact.items.item2')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].foodtech}`}>{t('Contact.items.item3')}</NavLink></li>
                        </ul>
                        <ul>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].mobile}`}>{t('Contact.items.item4')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].web}`}>{t('Contact.items.item5')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].blockchain}`}>{t('Contact.items.item6')}</NavLink></li>
                        </ul>
                        <ul>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].startup}`}>{t('Contact.items.item7')}</NavLink></li>
                        </ul>
                    </Box>
                </Box>
            </Box >
        </>
    )
}

export default Gdpr