import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { Box, Text, Button } from '@chakra-ui/react';
import productowner from '../../assets/images/team/productowner.webp';
import backend from '../../assets/images/team/Firefly developper man avatar with purple glasses and black background using computer and wearing a (3).svg';
import frontend from '../../assets/images/team/Group 330.svg';
import ui from '../../assets/images/team/Group 355.svg';
import fullstack from '../../assets/images/team/Firefly-developper-man-avatar-with-purple-glasses-and-black-background-using-computer-and-wearing-a-.webp';
import right from '../../assets/images/Arrow 3.svg';
import left from '../../assets/images/Arrow 3 (1).svg';
import './SectionTeam.css';

function SectionTeam() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.split('-')[0];
  const isRTL = currentLang === 'ar';
  const [currentIndex, setCurrentIndex] = useState(2);
  const sliderRef = useRef(null);

  // const settings = {
  //   centerMode: true,
  //   infinite: true,
  //   slidesToShow: 3,
  //   speed: 500,
  //   beforeChange: (current, next) => setCurrentIndex(next),
    // responsive: [
    //   {
    //     breakpoint: 700,
    //     settings: {
    //       slidesToShow: 1,
    //     },
    //   },
    // ],
  // };

  const settings = {
    className: "center slider-container-team",
    centerMode: true,
    infinite: true,
    centerPadding: "95px",
    slidesToShow: 3,
    speed: 500,
    beforeChange: (current, next) => setCurrentIndex(next),
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  //
  const paths = {
    en: { form: 'form', nosServices: 'our-services' },
    fr: { form: 'formulaire', nosServices: 'nos-services' },
    ar: { form: 'form', nosServices: 'our-services' },
  };

  const images = [productowner, backend, frontend, ui, fullstack];
  const titles = [
    t('team.product-owner'),
    t('team.backend-developer'),
    t('team.frontend-developer'),
    t('team.ui-designer'),
    t('team.full-stack-developer'),
  ];
  const texts = [
    t('team.product-owner-text'),
    t('team.backend-developer-text'),
    t('team.frontend-developer-text'),
    t('team.ui-designer-text'),
    t('team.full-stack-developer-text'),
  ];
  const descriptions = [
    `${t('team.product-owner-description')}`,
    `${t('team.backend-developer-description')}`,
    `${t('team.frontend-developer-description')}`,
    `${t('team.ui-designer-description')}`,
    `${t('team.full-stack-developer-description')}`,
  ];

  useEffect(() => {
    setCurrentIndex(0);
  }, []);

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <Box backgroundColor="#020202">
      <div className="ourteamcontainer">
        <Text className={`${isRTL ? 'ourteamtitle titles-rtl' : 'ourteamtitle'}`}>{t('word6')}</Text>
        <Text className={`${isRTL ? 'ourteamsubtitle p-rtl' : 'ourteamsubtitle'}`}>{t('team-subtitle')}</Text>
        <p className={`${isRTL ? 'paragraphe p-rtl' : 'paragraphe'}`} id="text-rtl">
          {t('word8')} <b>{t('word9')}</b> {t('word12')} <b>{t('word14')}</b> {t('word10')}
          <b>{t('word15')}</b> {t('word18')} <b>{t('word16')}</b> {t('word17')} <b>{t('word11')}</b> {t('word.')}{' '}
          <b>{t('word13')}</b> <b>{t('desc12')}</b>
        </p>
        <div className="ourteambutton">
          <NavLink to={`/${currentLang}/${paths[currentLang].nosServices}`}>
            <button id={isRTL ? 'titles-rtl' : ''}>{t('button2')}</button>
          </NavLink>
        </div>
      </div>
      {/* className="slider-container-team" */}
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <div key={index} className={`slider-item-team ${index === currentIndex ? 'active' : 'inactive'}`}>
            <div className="img-container">
              <h1 className={`${isRTL ? 'teamrol desc-rtl' : 'teamrol'} ${index === currentIndex ? 'active-title' : 'inactive-title'}`}>{titles[index]}</h1>
              <img src={image} className={`${index === currentIndex ? 'active-img img-item' : 'inactive-img img-item'}`} alt={`team member ${index}`} />
              <p className={`${isRTL ? 'image-title desc-rtl' : 'image-title'} ${index === currentIndex ? 'active-text' : 'inactive-text'}`}>{texts[index]}</p>
              <p className={`${isRTL ? 'image-description desc-rtl' : 'image-description'} ${index === currentIndex ? 'active-description' : 'inactive-description'}`}>{descriptions[index]}</p>
            </div>
            {index === currentIndex && (
              <div className="button-container">
                <Button className="left-btn" onClick={goToPrev}>
                  <img className="arr" src={left} alt="left arrow" />
                </Button>
                <Button className="right-btn" onClick={goToNext}>
                  <img className="arr" src={right} alt="right arrow" />
                </Button>
              </div>
            )}
          </div>
        ))}
      </Slider>
    </Box>
  );
}

export default SectionTeam;
