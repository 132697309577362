import React, { useState } from 'react';
import { Box, Button, Flex, HStack, Text, Image, GridItem } from "@chakra-ui/react";
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import footerlogo from '../../assets/images/logofooter.svg';
import './CardArticle.css';
import arrowUp from '../../assets/images/Group 388 (1).png';
import rectangle from '../../assets/images/Rectangle 38.svg';

function CardArticle({ selectedCategory }) {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';
    const [visibleCards, setVisibleCards] = useState(5);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const paths = {
        en: {
            rgpd: 'GDPR',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
        fr: {
            rgpd: 'RGPD',
            nosServices: 'nos-services',
            fintech: 'nos-services/developpement-fintech',
            ia: 'nos-services/developpement-ia',
            foodtech: 'nos-services/developpement-foodtech',
            mobile: 'nos-services/developpement-application-mobile',
            web: 'nos-services/developpement-site-web',
            blockchain: 'nos-services/developpement-blockchain',
            startup: 'nos-services/developpement-start-up',
        },
        ar: {
            rgpd: 'GDPR',
            form: 'form',
            nosServices: 'our-services',
            fintech: 'our-services/fintech-development',
            ia: 'our-services/ai-development',
            foodtech: 'our-services/foodtech-development',
            mobile: 'our-services/mobile-application-development',
            web: 'our-services/website-development',
            blockchain: 'our-services/blockchain-development',
            startup: 'our-services/startup-development',
        },
    };

    const boxInfo = [
        { title: t('Blog.menu.development'), date: "01.11.2021", description: t('Contact.items.item1'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].fintech}` },
        { title: t('Blog.menu.ia'), date: "01.11.2021", description: t('Contact.items.item2'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].ia}` },
        { title: t('Blog.menu.development'), date: "01.11.2021", description: t('Contact.items.item3'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].foodtech}` },
        { title: t('Blog.menu.development'), date: "01.11.2021", description: t('Contact.items.item4'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].mobile}` },
        { title: t('Blog.menu.development'), date: "01.11.2021", description: t('Contact.items.item5'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].web}` },
        { title: t('Blog.menu.development'), date: "01.11.2021", description: t('Contact.items.item7'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].startup}` },
        { title: t('Blog.menu.blockchain'), description: t('Contact.items.item6'), username: "NAME WRITER HERE", job: "UI / UX DESIGNER SENIOR", link: `${paths[currentLang].blockchain}` },
    ];

    const filteredBoxInfo = selectedCategory === t('Blog.menu.development') ? boxInfo : boxInfo.filter(box => box.title === selectedCategory);


    return (
        <>
            <Box
                bgPosition="center"
                bgSize="cover"
                backgroundColor='black'
                height="auto"
                width="100%"
                position="relative"
                overflow={'hidden'}
            >
                <Flex justify="center" align="center">
                    <div className="grid-container">
                        {filteredBoxInfo.slice(0, visibleCards).map((box, index) => (
                            <GridItem key={index} backgroundColor='rgba(255, 255, 255, 0.08)' padding={25} borderRadius={10} ml={20} mr={20} >
                                <Text
                                    id={isRTL ? 'titles-rtl' : ''}
                                    className={
                                        box.title === t('Blog.menu.development') ? "category-fintech" :
                                            box.title === t('Blog.menu.ia') ? "category-design" :
                                                box.title === t('Blog.menu.blockchain') ? "category-category" :
                                                    "category"
                                    }
                                >
                                    {box.title}
                                </Text>
                                <HStack justifyContent="space-between" alignItems="baseline" id={`${isRTL ? 'card-titles-rtl' : ''}`}>
                                    <Text id={`${isRTL ? 'titles-rtl' : ''}`} className="article_title">{box.description}</Text>
                                    <NavLink to={`/${currentLang}/` + box.link}>
                                        <Button id={`${isRTL ? 'titles-rtl' : ''}`} className="article_button">{t('Service.readmore')}</Button>
                                    </NavLink>
                                </HStack>
                            </GridItem>
                        ))}
                    </div>
                </Flex>
                {/* {visibleCards && boxInfo.length && (
                    <Flex justify="center" align="center">
                        <Button width={130} h={45} mt={30} onClick={handleLoadMore}>{t('Service.loadmore')}</Button>
                    </Flex>
                )} */}
                <img id='blogfooter' src={rectangle} alt='rectangle' />
                <button style={{ bottom: '200px' }} className="arrow-up-container" onClick={scrollToTop}>
                    <img src={arrowUp} alt="arrow up" className="arrow-up" />
                </button>
                <div style={{ marginTop: "120px" }}>

                    <Flex justifyContent="center" alignItems="center" direction="column">
                        <ul className="footer-menu1" id='footermenu2'>
                            <li className='logo'><Image id='logo' src={footerlogo} alt="Logo" style={{ marginRight: '15px', marginLeft: '25px' }} /></li>
                            <li className={`${isRTL ? 'li-rtl' : 'li'}`}>
                                <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')}</NavLink> - <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc16')}</NavLink>
                            </li>
                            <li className={`${isRTL ? 'li-rtl' : 'li'}`}>
                                <NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>© 2024 Omniverselab </NavLink>-<NavLink to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc17')}</NavLink>
                            </li>
                        </ul>
                    </Flex>

                    <Flex justifyContent="center" alignItems="center">
                        <Flex
                            className="footer-menu-res"
                            id='footermenu'
                            align="center"
                            justify="center"
                            width="100%"
                            fontFamily="Poppins"
                            fontSize="16px"
                            fontWeight='200'
                            lineHeight="24px"
                            letterSpacing="0.1em"
                            textAlign="left"
                        >
                            <Flex flex="1" justify="flex-end">
                                <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc15')} - </NavLink>
                                <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc16')}</NavLink>
                            </Flex>
                            <Image src={footerlogo} alt="Logo" boxSize="60px" mx="25px" />
                            <Flex flex="1" justify="flex-start">
                                <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>&copy; 2024 Omniverselab - </NavLink>
                                <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].rgpd}`}>{t('desc17')} </NavLink>
                            </Flex>
                        </Flex>
                    </Flex>
                </div>
                <Text id={`${isRTL ? 'footer-rtl' : 'nos-service'}`} display='flex' justifyContent='center' color='rgba(255, 255, 255, 0.7)' fontSize='16px' fontFamily='Poppins' lineHeight='24px' mt={10} fontWeight={200}>{t('Contact.title')}</Text>
                <Box className='footercontent-container'>
                    <Box className='footercontent' id={`${isRTL ? 'footer-rtl' : ''}`}>
                        <ul  >
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].fintech}`}>{t('Contact.items.item1')}</NavLink></li>
                            <li> <NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].ia}`}>{t('Contact.items.item2')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].foodtech}`}>{t('Contact.items.item3')}</NavLink></li>
                        </ul>
                        <ul>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].mobile}`}>{t('Contact.items.item4')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].web}`}>{t('Contact.items.item5')}</NavLink></li>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].blockchain}`}>{t('Contact.items.item6')}</NavLink></li>
                        </ul>
                        <ul>
                            <li><NavLink id={`${isRTL ? 'footer-rtl' : ''}`} to={`/${currentLang}/${paths[currentLang].startup}`}>{t('Contact.items.item7')}</NavLink></li>
                        </ul>
                    </Box>
                </Box>
            </Box >
        </>
    );
}

export default CardArticle;
