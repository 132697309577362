import React, { useEffect, useState } from 'react';
import { HStack } from '@chakra-ui/react';
import Logo from '../Logo/Logo';
import logo from '../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './Navbar.css'
function Navbar() {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';

    const paths = {
        en: {
            form: 'form',
            nosServices: 'our-services',
        },
        fr: {
            form: 'formulaire',
            nosServices: 'nos-services',
        },
        ar: {
            form: 'form',
            nosServices: 'our-services',
        },
    };

    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 65) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scrolled', handleScroll);
        };
      }, []);

      const classNames = ['headeritems']

      if (scrolled) {
        classNames.push('scrolled');
      }

    return (
        <HStack display='flex' gap='0px' className={classNames.join(' ')}>
            <div alt="logo" className="header-logo" >
                <Logo />
            </div>
            <div className='headerblog'>
                <NavLink to={`/${currentLang}/${paths[currentLang].nosServices}`} aria-label={t('Contact.title')}>
                    <span className='ourservices'>{t('Contact.title')}</span>
                </NavLink>
                <NavLink to={`/${currentLang}/${paths[currentLang].form}`} aria-label={t('contact')}>
                    <button className="contactButton">{t('contact')}</button>
                </NavLink>
            </div>
        </HStack>
    )
}

export default Navbar