import React, { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import './Feature.css';
import page from '../../assets/images/services/Group 318.jpg';
import page2 from '../../assets/images/services/Group 325 (1).jpg';
import page1 from '../../assets/images/services/Group 326.jpg';
import stars from '../../assets/images/Rectangle-369-_2_-_1_.webp';
import arrow from '../../assets/images/Arrow 3 (2).svg';
import Slider from 'react-slick';
import activeDot from '../../assets/images/active.svg';
import inactiveDot from '../../assets/images/not active.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Feature() {

    const { t, i18n } = useTranslation();
    const [images] = useState([
        { src: page1, caption: t('Blog.menu.ia'), button: t('button2'), desc: t('desc19') },
        { src: page, caption: t('Blog.menu.webetmobile'), button: t('button2'), desc: t('desc19') },
        { src: page2, caption: t('Blog.menu.blockchain'), button: t('button2'), desc: t('desc19') },
    ]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [slideDirection, setSlideDirection] = useState('left');
    const slickSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1.5,
        arrows: false,
    };
    const currentLang = i18n.language.split('-')[0];
    const isRTL = currentLang === 'ar';
    const sliderRef = useRef(null);

    const prevSlide = () => {
        sliderRef.current.slickNext();
    };

    const handleSlideChange = (index) => {
        setActiveIndex(index);
    };
    const paths = {
        en: {
            form: 'form',
            nosServices: 'our-services',
        },
        fr: {
            form: 'formulaire',
            nosServices: 'nos-services',
        },
        ar: {
            form: 'form',
            nosServices: 'our-services',
        },
    };
    return (
        <Box
            bgImage={`url(${stars})`}
            bgPosition="center"
            bgSize="cover"
            overflowX='hidden'
        >
            <div className="conteneur">
                <div className="description">
                    <div className={isRTL ? 'container-rtl' : 'container-ltr-section'}>
                        <h1 className="h1-style2" id={isRTL ? '' : 'owerservices'} style={{ marginLeft: '-5px', marginTop: '20px', marginBottom: '-10px' }}>{t('title2')}</h1>
                        <h2 className='sous-titre'>{t('title3')} </h2>
                        <p className='paragraphe' id={isRTL ? 'marginP-rtl' : 'marginP-ltr'} >{t('title4')} <b>{t('title5')} </b> {t('title6')} <b>{t('title7')} </b>{t('title8')}<b> {t('title9')} </b> {t('title10')}  <b> {t('title11')} </b></p>
                        <p className='paragraphe remove' id={isRTL ? 'marginP-rtl' : 'marginP-ltr'} >{t('desc18')}</p>
                    </div>
                </div>
                <button className='flechenext' onClick={prevSlide}><img src={arrow} alt="Left Arrow" /></button>

                <Slider
                    className="image-container"
                    onTouchMove={(e) => e.preventDefault()}
                    {...slickSettings}
                    ref={sliderRef}
                    onSwipe={(direction) => {
                        setSlideDirection(direction === 'left' ? 'left' : 'left');
                    }}
                    afterChange={handleSlideChange}
                >
                    {images.map((image, index) => (
                        <div key={index} className="slide">
                            <img src={image.src} alt={`Slide ${index}`} className="image" style={{ [slideDirection === 'left' ? 'marginLeft' : 'marginRight']: '-50%' }} />
                            <div className="caption">
                                <div className='cardheader'>
                                    <HStack display='flex'>
                                        <div className={`${isRTL ? 'Htitle titles-rtl' : 'Htitle'}`}>{image.caption} </div>
                                        <button id={`${isRTL ? 'titles-rtl' : ''}`}>                                         <NavLink to={`/${currentLang}/${paths[currentLang].nosServices}`}>
                                            {image.button}</NavLink></button>
                                    </HStack>
                                    <div className={`${isRTL ? 'desc desc-rtl' : 'desc'}`}>{image.desc}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className="dots-container">
                {[0, 1, 2].map((index) => (

                    <img
                        className='dotimage'
                        key={index}
                        src={index === activeIndex ? activeDot : inactiveDot}
                        alt={index === activeIndex ? 'active dot' : 'inactive dot'}

                    />
                ))}
            </div>
        </Box >
    );
}

export default Feature;
