import React from 'react';
import { Box, VStack, HStack, Text } from '@chakra-ui/react';
import background from '../../assets/images/brends/Group-389-_1_.webp';
import brend1 from '../../assets/images/brends/image 4.svg';
import brend2 from '../../assets/images/brends/image 3.png';
import brend3 from '../../assets/images/brends/Group 295.svg';
import brend4 from '../../assets/images/brends/Group 244.svg';
import brend5 from '../../assets/images/brends/Group 272.svg';

import './Brends.css';

function Brends() {
    const brendImages = [brend1, brend5, brend4, brend3, brend2];

    const duplicateImages = (images, count) => {
        const duplicated = [];
        for (let i = 0; i < count; i++) {
            duplicated.push(...images);
        }
        return duplicated;
    };

    const duplicatedImages = duplicateImages(brendImages, 3);

    return (
        <Box
            className='brendbg'
            bgImage={background}
            paddingBottom={30}
        >
            <VStack pt={10} pb={20} >
                <HStack
                    pt={20}
                    width='100%'
                    display='flex'
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}

                >
                    <Box
                        height="1px"
                        width="100%"
                        backgroundColor="#ffffff"
                        ml={120}
                        className='Hline1'
                    />
                    <Text className='caroussel' style={{
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontStyle: 'italic',
                        fontWeight: 900,
                        lineHeight: '24px',
                        letterSpacing: '0.1em',
                        marginBottom: '0',
                        color: 'white',
                        textAlign: 'center',
                        width: '100%'

                    }}>
                        ILS NOUS ONT FAIT CONFIANCE
                    </Text>
                    <Box
                        height="1px"
                        width="100%"
                        mr={120}
                        className='Hline2'
                        backgroundColor="#ffffff"
                    />
                </HStack>
                {/* <HStack className="slider-container">
                    <div className="slider-item-group">
                        {duplicatedImages.map((image, index) => (
                            <img key={index} src={image} alt={`brend-${index}`} className="slider-item" />
                        ))}
                    </div>
                </HStack> */}
                <div class="logos">
                    <div class="logos-slide">
                        {duplicatedImages.map((image, index) => (
                            <img key={index} src={image} alt={`brend-${index}`} />
                        ))}
                    </div>
                    <div class="logos-slide">
                        {duplicatedImages.map((image, index) => (
                            <img key={index} src={image} alt={`brend-${index}`} />
                        ))}
                    </div>
                    <div class="logos-slide">
                        {duplicatedImages.map((image, index) => (
                            <img key={index} src={image} alt={`brend-${index}`} />
                        ))}
                    </div>
                    <div class="logos-slide">
                        {duplicatedImages.map((image, index) => (
                            <img key={index} src={image} alt={`brend-${index}`} />
                        ))}
                    </div>
                </div>

            </VStack>
            <Box
                height="1px"
                backgroundColor="#ffffff"
                ml={120}
                mr={120}
                mt={4}
                className='Hline'
            />
        </Box>
    );
}

export default Brends;
